import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)``

export const Orders = styled.div`
  max-width: 143.5rem;
  width: 100%;
  margin-bottom: 6.2rem;
`

export const OrdersIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`

export const OrdersTop = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6.3rem;
`

export const OrdersTitle = styled.h2`
  margin-right: 2rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
`

export const OrdersQty = styled.span`
  padding: 0 0.8rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  background-color: ${({ theme }) => theme.colors.yellow.default};
  border-radius: 10rem;
`

export const OrdersTable = styled.div`
  margin-top: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  @media ${({ theme }) => theme.screens.md} {
    display: none;
  }
`
export const OrdersTableMobile = styled.div`
  margin-top: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  display: none;
  @media ${({ theme }) => theme.screens.md} {
    display: block;
    border: none;
  }
`

export const OrdersTableItem = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  padding: 1.162rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-left: 0;
  border-top: 0;
`

export const OrdersTableItemLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.orange.default};

  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }

  ${OrdersIcon} {
    margin-right: 0.8rem;
  }

  ${StyledFontAwesomeIcon} {
    margin-right: 0.8rem;
  }
`

export const OrdersTableItemLinkId = styled(OrdersTableItemLink)`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
`

export const OrdersTableRow = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.mono.white};
  transition: background 0.3s;
  @media ${({ theme }) => theme.screens.md} {
    flex-wrap: wrap;
  }
  &:hover {
    background: #fafafa;
  }

  &:last-of-type {
    ${OrdersTableItem} {
      border-bottom: 0;
    }
  }

  ${OrdersTableItem} {
    text-align: center;

    &:nth-of-type(1) {
      width: 5.4%;
      @media ${({ theme }) => theme.screens.md} {
        width: 33.33%;
        border-left: 1px solid ${({ theme }) => theme.colors.mono.grey};
      }
    }
    &:nth-of-type(2) {
      width: 8.9%;
      @media ${({ theme }) => theme.screens.md} {
        width: 33.33%;
      }
    }
    &:nth-of-type(3) {
      width: 7.4%;
      @media ${({ theme }) => theme.screens.md} {
        width: 33.33%;
        border-right: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
      }
    }
    &:nth-of-type(4) {
      width: 10%;
      @media ${({ theme }) => theme.screens.md} {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        border: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
        margin-bottom: 2rem;
      }
    }
    &:nth-of-type(5) {
      flex-grow: 1;
      text-align: left;
    }
    &:nth-of-type(6) {
      width: 10.6%;
    }
    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9) {
      width: 4.45%;
    }
    &:last-of-type {
      border-right: 0;
    }
  }
`

export const OrdersTableHeader = styled(OrdersTableRow)`
  background: ${({ theme }) => theme.colors.mono.greyLight};
  &:hover {
    background: ${({ theme }) => theme.colors.mono.greyLight};
  }

  ${OrdersTableItem} {
    padding: 0.65rem 2.2rem;
    &:nth-of-type(6) {
      width: 24%;
    }
  }
`

export const OrdersButton = styled.button`
  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`
